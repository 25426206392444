var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex justify-space-between flex-column flex-grow-1"},[_c('div',{staticClass:"mt-n4"},[_c('h6',{staticClass:"text-h6 pb-4"},[_vm._v("Choose an install type")]),_c('div',{staticClass:"text-body-1"},[_c('p',[_vm._v(" This installer can help you update from an older version of "+_vm._s(_vm.$root.$data.OS_NAME)+" or switch to it from another OS, but you need to choose which one you want. ")])])]),_c('div',{staticClass:"d-flex flex-wrap justify-space-around"},[_c('v-card',{staticClass:"ma-4 d-flex flex-column",class:_vm.$root.$data.installType === 'clean'
                    ? 'v-card--selected'
                    : null,attrs:{"outlined":"","max-width":"16rem","ripple":"","color":_vm.$root.$data.installType === 'clean'
                    ? 'grey lighten-4'
                    : null},on:{"click":function($event){return _vm.setType('clean')}}},[_c('v-card-title',{staticClass:"mt-n2"},[_c('v-icon',{staticClass:"pr-2 py-2",attrs:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v("mdi-cellphone-erase")]),_vm._v(" Clean install")],1),_c('v-card-subtitle',[_vm._v("Switch from the stock OS or another ROM to "+_vm._s(_vm.$root.$data.OS_NAME)+". "),_c('strong',{staticClass:"red--text text--darken-2"},[_vm._v("All data on your device will be lost.")])])],1),_c('v-card',{staticClass:"ma-4 d-flex flex-column justify-space-between",class:_vm.$root.$data.installType === 'update'
                    ? 'v-card--selected'
                    : null,attrs:{"outlined":"","max-width":"16rem","ripple":"","color":_vm.$root.$data.installType === 'update'
                    ? 'grey lighten-4'
                    : null},on:{"click":function($event){return _vm.setType('update')}}},[_c('div',[_c('v-card-title',{staticClass:"mt-n2"},[_c('v-icon',{staticClass:"pr-2 py-2",attrs:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v("mdi-update")]),_vm._v(" Update")],1),_c('v-card-subtitle',[_vm._v("Update from an older version of "+_vm._s(_vm.$root.$data.OS_NAME)+". Your data won’t be affected. ")])],1)])],1),_c('div',{staticClass:"d-flex justify-space-between flex-row-reverse"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.$root.$data.installType === null},on:{"click":function($event){return _vm.$bubble('nextStep')}}},[_vm._v("Next "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-arrow-right")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$bubble('prevStep')}}},[_vm._v("Back")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }